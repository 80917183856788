import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("About"),
    items: [
      {
        label: t("White Paper"),
        href: "https://drive.google.com/file/d/1yP3iSc6JfzIIip3-ACeNMdpxJeScDXSW/view",
      },
    ],
  },
  // {
  //   label: t("Help"),
  //   items: [
  //     {
  //       label: t("Customer Support"),
  //       href: "https://docs.pancakeswap.finance/contact-us/customer-support",
  //     },
  //     {
  //       label: t("Troubleshooting"),
  //       href: "https://docs.pancakeswap.finance/help/troubleshooting",
  //     },
  //     {
  //       label: t("Guides"),
  //       href: "https://docs.pancakeswap.finance/get-started",
  //     },
  //   ],
  // }
];
