import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Long swap',
  defaultTitle: 'Long swap',
  description: '',
  twitter: {
    // cardType: 'summary_large_image',
    // handle: '@PancakeSwap',
    // site: '@PancakeSwap',
  },
  openGraph: {
    title: 'Long swap',
    description: 'The most popular AMM on BSC by user count!',
    images: [{ url: 'https://swap.longdao.cc/images/home/home/hero.png' }],
  },
}
