import memoize from 'lodash/memoize'
import { ContextApi } from '@pancakeswap/localization'
import { PageMeta } from './types'

const img = 'https://swap.longdao.cc/images/home/home/hero.png'

export const DEFAULT_META: PageMeta = {
  title: 'Long swap',
  description: 'The most popular AMM on BSC by user count!',
  image: img,
}

interface PathList {
  paths: { [path: string]: { title: string; basePath?: boolean; description?: string; image?: string } }
  defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
  return {
    paths: {
      '/': { title: t('Home') },
      '/swap': { basePath: true, title: t('Exchange'), image: img },
      '/limit-orders': { basePath: true, title: t('Limit Orders'), image: img },
      '/add': { basePath: true, title: t('Add Liquidity'), image: img },
      '/remove': { basePath: true, title: t('Remove Liquidity'), image: img },
      '/liquidity': { title: t('Liquidity'), image: img },
      '/competition': { title: t('Trading Battle') },
      '/prediction': { title: t('Prediction'), image: img },
    },
    defaultTitleSuffix: 'Long swap',
  }
}

export const getCustomMeta = memoize(
  (path: string, t: ContextApi['t'], _: string): PageMeta => {
    const pathList = getPathList(t)
    const pathMetadata =
      pathList.paths[path] ??
      pathList.paths[Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]]

    if (pathMetadata) {
      return {
        title: `${pathMetadata.title}`,
        ...(pathMetadata.description && { description: pathMetadata.description }),
        image: pathMetadata.image,
      }
    }
    return null
  },
  (path, t, locale) => `${path}#${locale}`,
)
