import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <img src='/images/home/home/logo.png' alt='' {...props} />
  );
};

export default Icon;
